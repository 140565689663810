import { ChainId, ERC20Token, Token, WBNB, WETH9, WETHARB } from '@pancakeswap/sdk'
import { BUSD_BSC, CAKE_MAINNET, USDT_BSC } from './common'


export const arbTokens ={
  weth: WETHARB[ChainId.ARBITRUM],
  eth: new ERC20Token(
    ChainId.ARBITRUM,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'ETH',
    'ETH',
    'https://www.ethereum.org/',
  ),
}


